import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import Helmet from 'react-helmet';
import { localizedPath } from 'gatsby-theme-i18n/src/helpers';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import styled from 'styled-components';

import '../globalStyles.css';
import Layout from '../components/Layout';

const SubpageContainer = styled.div`
  max-width: 1920px;
  margin: auto;
  padding: 0 100px;

  @media (max-width: 1250px) {
    padding: 0 50px;
  }

  @media (max-width: 550px) {
    padding: 0;
  }
`;

const MainSection = styled.div`
  position: relative;
`;

const SubpageHeader = styled.div`

`;

const Headline = styled.h1`
  font-size: 60px;
  color: var(--color-teal);
  white-space: pre-wrap;
  margin-bottom: 1.5%;

  & p {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: 1450px) {
    font-size: 55px;
  }

  @media (max-width: 1250px) {
    font-size: 44px;
  }

  @media (max-width: 900px) {
    font-size: 40px;
  }

  @media (max-width: 750px) {
    font-size: 37px;
    padding-bottom: 2%;
  }

  @media (max-width: 550px) {
    font-size: 29px;
    width: 90%;
    margin: auto;
    padding-bottom: 3%;
    padding-top: 2.5%;
  }
`;

const Subtext = styled.h3`
  color: var(--color-dark-grey);
  white-space: pre-wrap;
  font-size: 18px;
  margin-top: 0;
  width: 75%;

  & p {
    margin-top: 0;
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1250px) {
    font-size: 17px;
    width: 100%;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 90%;
    margin: auto;
    white-space: normal;
  }

`;

const Section1Subpage = styled.div`
  color: var(--color-dark-grey);
  display: flex;
  padding-bottom: 4%;
  padding-top: 1%;
  justify-content: space-between;

  @media (min-width: 1921px) {
    padding-top: 0;
  }
  
  @media (max-width: 1023px) {
    flex-direction: column;
    padding-bottom: 0;
  }

  @media (max-width: 750px) {
    padding-top: 1%;
  }
`;

const TextBlockLeft = styled.div`
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  width: 50%;
  font-size: 17px;
  flex-direction: column;
  line-height: 1.25;

  & p {
    width: 90%;
    margin-block-end: 0
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 1250px) {
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    width: 100%;

    & p {
      width: 100%;
    }
  }

  @media (max-width: 550px) {
    font-size: 15px;
    white-space: normal;
    width: 90%;
    margin: auto;
  }
  
`;

const ImageWrapper = styled.div`
  width: 45%;
  padding-top: 33.51%;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;
  height: 0;
  margin: auto;

  @media (max-width: 1023px) {
    width: 75%;
    padding-top: 55.85%;
    margin: auto;
  }

  @media (max-width: 750px) {
    width: 90%;
    padding-top: 67.02%;
  }
`;

const ImageContainerRight = styled.div`
  background-image: url(/img/mockup_tablet.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  @media (min-width: 1921px) {
    max-width: 774px;
  }
`;

const ScreenContainer = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  height: 100%;
  width: 90.5%;
  max-width: 704.33px;
  position: absolute;
  left: 5.2%;
  right: 0;
  top: 0.2%;
  bottom: 0;

  @media (min-width: 1921px) {
    width: 90.5%;
    left: 4.7%;
    right: 0;
    top: 0.2%;
    bottom: 0;  
  }

  @media (max-width: 1920px) {
    width: 90.5%;
    left: 5.2%;
    right: 0;
    top: 0.2%;
    bottom: 0;  
  }
`;

const Section2Subpage = styled.div`
  color: var(--color-dark-grey);
  display: flex;
  width: 100%;
  padding-bottom: 5%;
  
  @media (max-width: 1023px) {
    flex-direction: column-reverse;
    padding-bottom: 8%;
  }
`;

const SlideContainer = styled.div`
  width: 50%;
  margin: auto;

  & button {
    padding: 7px 11px;
  }
  
  & ul {
    padding-inline-start: 0;
  }

  & .react-slideshow-container+ul.indicators {
    margin-top: 10px;
    margin-bottom: 0;
  }

  & .react-slideshow-container+ul.indicators li {
    width: 33px;
    padding: 0;
  }

  & .react-slideshow-container + ul.indicators .each-slideshow-indicator::before {
    background: var(--color-teal);
    width: 9px;
    height: 7px;
    position: relative;
  }

  @media (max-width: 1023px) {
    padding-top: 8%;
    width: 90%;
  }

`;

const Image = styled.div`
  background-image: url("${(props) => props.url}");
  background-size: contain;
  background-repeat: no-repeat;
  margin: auto;
  width: 35vw;
  height: calc(35vw / 16 * 9);
  max-width: 750px;
  max-height: calc(750px / 16 * 9);


  @media (max-width: 1250px) {
    width: 45vw;
    height: calc(45vw / 16 * 9);  
  }

  @media (max-width: 1023px ) {
    width: 55vw;
    height: calc(55vw / 16 * 9);
  }

  @media (max-width: 750px) {
    width: 70vw;
    height: calc(70vw / 16 * 9);
  }

  @media (max-width: 550px) {
    width: 85vw;
    height: calc(85vw / 16 * 9);
  }

`;

const TextBlockRight = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  white-space: pre-wrap;
  width: 50%;
  font-size: 17px;
  line-height: 1.25;

  & p {
    width: 95%;
    margin-left: auto;
    margin-block-end: 0
  }

  @media (max-width: 1250px) {
    font-size: 16px;
  }

  @media (max-width: 1023px) {
    width: 100%;

    & p {
      width: 100%;
    }
  }

  & a {
    color: var(--color-dark-grey);
    text-decoration: none;
    transition: all ease 0.3s;
    background-size: auto 175%;
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.6),
      0 4px 0 -3px rgba(52, 191, 201, 0.6),
      inset 0 0 0 0 rgba(52, 191, 201, 0.6);
  }
  
  & a:hover {
    background-position-y: 105%;
    color: var(--color-white);
    box-shadow: 0 1px 0 0 rgba(52, 191, 201, 0.8),
      0 0px 0 2px rgba(52, 191, 201, 0.8),
      inset 0 -1.5em 0 0 rgba(52, 191, 201, 0.8);
  }

  @media (max-width: 550px) {
    font-size: 15px;
    white-space: normal;
    width: 90%;
    margin: auto;
  }
`;

const ContactPageForwarding = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5% 8%;
  background-color: var(--color-light-yellow);
  width: 42%;
  margin: auto;
  margin-top: 10%;
  margin-bottom: 10%;
  text-align: center;
  border-radius: 100px;
  position: relative;
  white-space: pre-wrap;
  font-size: 17px;
  line-height: 1.25;

  @media (max-width: 1500px) {
    width: 50%;
  }

  @media (max-width: 1250px) {
    font-size: 16px;
    margin-top: 12%;
  }

  @media (max-width: 1023px) {
    width: 60%;
    margin-top: 15%;
  }

  @media (max-width: 900px) {
    padding: 5% 11%;
    font-size: 15px;
  }

  @media (max-width: 700px) {
    width: 65%;
    padding: 5% 15%;
  }

  @media (max-width: 550px) {
    font-size: 15px;
    width: 100%;
    border-radius: 0;
    padding: 0;
    padding-bottom 7%;
    margin-top: 20%;
  }

  & p {
    margin: auto;
    color: var(--color-dark-grey);
    padding-bottom: 7%;
    font-weight: 700;

    @media (max-width: 550px) {
      padding: 7% 15%;
    }

    @media (max-width: 430px) {
      padding: 7% 12%;
    }
  }
`;

const RequestDemoButton = styled.button`
  width: 230px;
  color: var(--color-dark-grey);
  margin: auto;

  @media (max-width: 900px) {
    font-size: 17px;
    width: 220px;
  }

  @media (max-width: 550px) {
    font-size: 16px;
    width: 190px;
  }

  @media (max-width: 440px) {
    font-size: 15px;
    width: 180px;
  }
`;

const IconPageForwarding = styled.div`
  background-image: url(/img/icon_contact_component.png);
  background-size: contain;
  background-repeat: no-repeat;
  height: 80%;
  width: 25%;
  position: absolute;
  left: 85%;
  right: 0;
  top: -38%;
  bottom: 0;

  @media (max-width: 1250px) {
    width: 30%;
    height: 70%;
    left: 84%;
    top: -42%;
  }

  @media (max-width: 1023px) {
    left: 86%;
    top: -35%;
  }

  @media (max-width: 900px) {
    left: 84%;
  }

  @media (max-width: 700px) {
    height: 58%;
    width: 33%;
    top: -30%;
    left: 80%;
  }

  @media (max-width: 550px) {
    height: 50%;
    width: 24%;
    top: -23%;
    left: 0%;
  }

  @media (max-width: 430px) {
    height: 31%;
    width: 23%;
    top: -16%;
    left: -1%;
  }
`;

const VideoSection = styled.div`
  display: flex;
  justify-content: center;
  height: calc((65vw - 200px) * 0.5625);
  padding-top: 5%;
  padding-bottom: 8%;

  @media (min-width: 1921px) {
    width: 1122px;
    height: 593.5px;
    margin: auto;
  }

  @media (max-width: 1250px) {
    height: calc((65vw - 100px) * 0.5625);
  }

  @media (max-width: 900px) {
    height: calc((100vw - 100px) * 0.5625);
  }

  @media (max-width: 550px) {
    height: calc(90vw * 0.5625);
  }

  & iframe {
    width: 56.25%;
    height: 100%;
    border: none;

    @media (min-width: 1921px) {
      width: 1122px;
      height: 593.5px;
    }

    @media (max-width: 900px) {
      width: 100%;
    }
    
    @media (max-width: 550px) {
      width: 90%;
    }
  }
`;

const SubpageWrapper = styled.div`

`;
export default class Subpages extends Component {
  static propTypes = {
    pageContext: PropTypes.object,
    data: PropTypes.object,
  };

  // eslint-disable-next-line class-methods-use-this
  renderContent(page) {
    const id = page?.subpage?.id || Math.random();

    const properties = {
      duration: 5000,
      transitionDuration: 500,
      infinite: true,
      indicators: true,
      arrows: false,
    };

    const submenu = page?.submenus?.edges?.find((menu) => menu?.node?.frontmatter?.submenuId === page?.subpage?.frontmatter?.submenuId)?.node;

    return (
      <SubpageContainer key={id}>
        <MainSection>
          <SubpageHeader>
            <Headline>
              <MDXRenderer>{page?.subpage?.frontmatter?.submenuSubheadline}</MDXRenderer>
            </Headline>
            <Subtext>
              {page?.subpage?.frontmatter?.subpageSubtext}
            </Subtext>
          </SubpageHeader>
          <Section1Subpage>
            <TextBlockLeft>
              <MDXRenderer>{page?.subpage?.frontmatter?.subpageTextblockLeft}</MDXRenderer>
            </TextBlockLeft>
            <ImageWrapper>
              <ScreenContainer url={page?.subpage?.frontmatter?.subpageImageRight} />
              <ImageContainerRight />
            </ImageWrapper>
          </Section1Subpage>
          <Section2Subpage>
            <SlideContainer>
              <Slide {...properties}>
                {page?.subpage?.frontmatter?.subpageGridImages?.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Image key={index} url={item.subpageGridImage} />
                ))}
              </Slide>
            </SlideContainer>
            <TextBlockRight>
              <MDXRenderer>{page?.subpage?.frontmatter?.subpageTextblockRight}</MDXRenderer>
            </TextBlockRight>
          </Section2Subpage>
        </MainSection>
        <ContactPageForwarding>
          <MDXRenderer>{submenu?.frontmatter?.linkToContactText}</MDXRenderer>
          <RequestDemoButton
            type="button"
            onClick={() => {
              navigate(localizedPath({
                locale: `${submenu?.fields?.locale}`,
                path: '/contact',
                defaultLang: 'de',
                prefixDefault: false,
              }));
            }}
          >
            { submenu?.frontmatter?.buttonText || 'BUTTONTEXT NOT FOUND' }
          </RequestDemoButton>
          <IconPageForwarding />
        </ContactPageForwarding>
        { page?.subpage?.frontmatter?.subpageVideo && (
          <VideoSection>
            <iframe
              src={page?.subpage?.frontmatter?.subpageVideo}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          </VideoSection>
        )}
      </SubpageContainer>
    );
  }

  render() {
    const { pageContext, data } = this.props;
    const page = data || {};

    let canonicalLinkPrefix;

    if (pageContext.locale === 'en') {
      canonicalLinkPrefix = 'https://presono.com/en';
    } else {
      canonicalLinkPrefix = 'https://presono.com';
    }

    return (
      <Layout currentPath={pageContext.originalPath} submenuId={page?.subpage?.frontmatter?.submenuId}>
        <Helmet>
          <title>{`${data?.subpage?.frontmatter?.titleBrowserTab} | presono`}</title>
          <meta
            name="description"
            content={data?.subpage?.frontmatter?.subpageSubtext}
          />

          <link rel="canonical" href={canonicalLinkPrefix + pageContext.originalPath} />

          <meta property="og:title" content={data?.subpage?.frontmatter?.titleBrowserTab} />
          <meta property="og:description" content={data?.subpage?.frontmatter?.subpageSubtext} />
          <meta property="og:image" content={`https://presono.com${data?.subpage?.frontmatter?.subpageImageRight}`} />
          <meta name="twitter:title" content={data?.subpage?.frontmatter?.titleBrowserTab} />
          <meta name="twitter:description" content={data?.subpage?.frontmatter?.subpageSubtext} />
          <meta name="twitter:image" content={`https://presono.com${data?.subpage?.frontmatter?.subpageImageRight}`} />
        </Helmet>
        <SubpageWrapper className="off-white-background">
          { this.renderContent(page) }
        </SubpageWrapper>
      </Layout>
    );
  }
}

export const query = graphql`
query SubpageQuery($title: String!, $locale: String!) {
  subpage: mdx(
    fields: {locale: {eq: $locale}}
    frontmatter: {title: {eq: $title}, layout: {eq: "subpage"}}
  ) {
    frontmatter {
      title
      titleBrowserTab
      submenuId
      subpageId
      submenuSubheadline
      subpageSubtext
      subpageTextblockLeft
      subpageImageRight
      subpageGridImages {
        subpageGridImage
      }
      subpageTextblockRight
      subpageVideo
    }
    id
    slug
  }
  submenus: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId] }
    filter: {
      frontmatter: { layout: {eq: "submenu"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          submenuId
          title
          linkToContactText
          buttonText
        }
        fields {
          locale
        }
      }
    }
  }
  subpages: allMdx(
    sort: { order: ASC, fields: [frontmatter___submenuId, frontmatter___subpageId] }
    filter: {
      frontmatter: { layout: {eq: "subpage"} }
      fields: {locale: {eq: $locale}}
    }
  ) {
    edges {
      node {
        frontmatter {
          title
          submenuId
          subpageId
          submenuSubheadline
        }
        slug
        id
        fields {
          locale
        }
      }
    }
  }
}
`;
